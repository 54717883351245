export const environment = {
    production: false,
    oktaConfig: {
      baseUrl: 'https://amway.okta.com/',
      issuer: 'https://amway.okta.com/oauth2/default',
      redirectUri: 'https://durables-admin-test.amway.net/main',
        logoutRedirectUri: 'https://durables-admin-test.amway.net/login',
      clientId: '0oa1h8lrq8cyHpZv40h8'
  },
    componentMinSerialLength: 17,
    maxSerialNumberLength: 17,
    minSerialNumberLength: 13,
    minSkuNumberLength: 6,
    AzureFunctionUrl: 'https://durableservices-test-api-functions.azurewebsites.net',
    apiKey: 'KouSa9wVUmsiNkuAKaPraZKtplNV2T3MuagiZZTxXbB6WZRs8XcQHg=='
  };
